import axios from 'axios';
import { getApiRoot } from '~/utilities/api';

axios.interceptors.request.use(async (config) => {
  if (config.url.startsWith(getApiRoot())) {
    const token = await getJwt();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

export default defineNuxtPlugin(() => {
  return {
    provide: {
      axios,
    },
  };
});

async function getJwt() {
  const { $Amplify } = useNuxtApp();

  try {
    const userSession = await $Amplify.Auth.fetchAuthSession();
    return userSession.tokens?.idToken;
  } catch {
    return undefined;
  }
}
