import * as Sentry from '@sentry/vue';
import { browserTracingIntegration } from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const {
    public: { sentry },
  } = useRuntimeConfig();

  if (!sentry.dsn) {
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    integrations: [browserTracingIntegration({ router })],

    // ignore popper error
    ignoreErrors: [
      "Cannot read properties of null (reading 'disconnect')", // VuePopper
      "null is not an object (evaluating 'o.disconnect')", // VuePopper
      'No matching key',
      '[Cloudflare Turnstile]',
    ],

    // Configure this whole part as you need it!

    tracesSampleRate: 0.1, // Change in prod

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'https://api.mintgarden.io', 'https://api.testnet.mintgarden.io'],

    replaysSessionSampleRate: 0.1, // Change in prod
    replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
  });
});
