import { createI18n } from 'vue-i18n';

import en from '../lang/en-US.json';
import zh from '../lang/zh-CN.json';

export default defineNuxtPlugin(({ vueApp }) => {
  const userLang = useCookie('user-lang');

  const i18n = createI18n({
    locale: userLang.value || 'en',
    fallbackLocale: 'en',
    legacy: false,
    globalInjection: true,
    messages: {
      en,
      zh,
    },
  });

  vueApp.use(i18n);
});
