import { Amplify } from 'aws-amplify';
import {
  autoSignIn,
  confirmResetPassword,
  confirmSignUp,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resendSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from 'aws-amplify/auth';

export default defineNuxtPlugin({
  name: 'AmplifyAPIs',
  enforce: 'pre',

  setup() {
    const config = useRuntimeConfig();

    // This configures Amplify on the client side of your Nuxt app
    Amplify.configure(
      {
        Auth: {
          Cognito: {
            userPoolId: config.public.cognitoUserPoolId,
            userPoolClientId: config.public.cognitoUserPoolWebClientId,
          },
        },
      },
      { ssr: true }
    );

    return {
      provide: {
        Amplify: {
          Auth: {
            fetchAuthSession,
            getCurrentUser,
            fetchUserAttributes,
            signUp,
            confirmSignUp,
            signIn,
            signOut,
            resetPassword,
            confirmResetPassword,
            resendSignUpCode,
            autoSignIn,
          },
        },
      },
    };
  },
});
