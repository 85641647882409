import { default as _91username_93iugdqiSYaXMeta } from "/app/pages/[username].vue?macro=true";
import { default as _91id_93ib8VxTGiCiMeta } from "/app/pages/address/[id].vue?macro=true";
import { default as auctionz1vqMOryKHMeta } from "/app/pages/admin/auction.vue?macro=true";
import { default as eventsXLVRVlBuumMeta } from "/app/pages/admin/events.vue?macro=true";
import { default as indexfuvksFNEGtMeta } from "/app/pages/auctions/index.vue?macro=true";
import { default as indexWHm9WMDUkCMeta } from "/app/pages/badger/index.vue?macro=true";
import { default as privacy_45policy2aYJU9s23JMeta } from "/app/pages/badger/privacy-policy.vue?macro=true";
import { default as support9V9Vpbod5nMeta } from "/app/pages/badger/support.vue?macro=true";
import { default as terms_45of_45servicehHxjwpBQQdMeta } from "/app/pages/badger/terms-of-service.vue?macro=true";
import { default as _91_46_46_46slug_93jK3Pyj8dSOMeta } from "/app/pages/blog/[...slug].vue?macro=true";
import { default as _91id_93skyE6bVvaPMeta } from "/app/pages/collections/[id].vue?macro=true";
import { default as indexv7p7e2w6rrMeta } from "/app/pages/collections/index.vue?macro=true";
import { default as callbackVIqFc1oLf6Meta } from "/app/pages/discord/callback.vue?macro=true";
import { default as index8T4PBHOxLqMeta } from "/app/pages/discord/index.vue?macro=true";
import { default as eventsk5yx3AVVmZMeta } from "/app/pages/events.vue?macro=true";
import { default as gobytOfADRZqdlMeta } from "/app/pages/goby.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as legal_45noticeNNxwVI9S92Meta } from "/app/pages/legal-notice.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as early_45accessctisd2vT87Meta } from "/app/pages/mint/early-access.vue?macro=true";
import { default as indexUxcQIpR6GxMeta } from "/app/pages/mint/index.vue?macro=true";
import { default as studio4i2Yxg5GiNMeta } from "/app/pages/mint/studio.vue?macro=true";
import { default as terms_45of_45servicenXeZoWlia8Meta } from "/app/pages/mint/terms-of-service.vue?macro=true";
import { default as _91id_93nYorKaOVoNMeta } from "/app/pages/mints/[id].vue?macro=true";
import { default as indexLBanV1CnnxMeta } from "/app/pages/mints/index.vue?macro=true";
import { default as new8XRwwjkl9dMeta } from "/app/pages/new.vue?macro=true";
import { default as indexRBbaGpDb0BMeta } from "/app/pages/nfts/[id]/index.vue?macro=true";
import { default as createNVwQmIo8kUMeta } from "/app/pages/nfts/create.vue?macro=true";
import { default as indexob1pC6VD6NMeta } from "/app/pages/nfts/index.vue?macro=true";
import { default as _91id_93wccPPBXqnRMeta } from "/app/pages/offers/[id].vue?macro=true";
import { default as uploadBbawmCa51zMeta } from "/app/pages/offers/upload.vue?macro=true";
import { default as passkey_45test1U4iIr5CfwMeta } from "/app/pages/passkey-test.vue?macro=true";
import { default as premiumEP1PXaA2PJMeta } from "/app/pages/premium.vue?macro=true";
import { default as _91id_93pYch2M9GePMeta } from "/app/pages/profile-embed/[id].vue?macro=true";
import { default as _91id_93Kujv0wYEYHMeta } from "/app/pages/profile/[id].vue?macro=true";
import { default as profilesq0TH7YXrSuMeta } from "/app/pages/profiles.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as didCfLdYe1mNuMeta } from "/app/pages/user/claim/did.vue?macro=true";
import { default as callback2gi6kDy7w0Meta } from "/app/pages/user/claim/discord/callback.vue?macro=true";
import { default as callbackUXz1QU7VLHMeta } from "/app/pages/user/claim/twitter/callback.vue?macro=true";
import { default as premiummGK9BexxYhMeta } from "/app/pages/user/premium.vue?macro=true";
import { default as profilesxSppIEAXTuMeta } from "/app/pages/user/profiles.vue?macro=true";
import { default as callbackgdPkb8euiAMeta } from "/app/pages/wallet/claim/discord/callback.vue?macro=true";
import { default as callbackNT3xMmcnmOMeta } from "/app/pages/wallet/claim/twitter/callback.vue?macro=true";
import { default as indexzk34bxS2TyMeta } from "/app/pages/wallet/index.vue?macro=true";
export default [
  {
    name: _91username_93iugdqiSYaXMeta?.name ?? "username",
    path: _91username_93iugdqiSYaXMeta?.path ?? "/:username()",
    meta: _91username_93iugdqiSYaXMeta || {},
    alias: _91username_93iugdqiSYaXMeta?.alias || [],
    redirect: _91username_93iugdqiSYaXMeta?.redirect,
    component: () => import("/app/pages/[username].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ib8VxTGiCiMeta?.name ?? "address-id",
    path: _91id_93ib8VxTGiCiMeta?.path ?? "/address/:id()",
    meta: _91id_93ib8VxTGiCiMeta || {},
    alias: _91id_93ib8VxTGiCiMeta?.alias || [],
    redirect: _91id_93ib8VxTGiCiMeta?.redirect,
    component: () => import("/app/pages/address/[id].vue").then(m => m.default || m)
  },
  {
    name: auctionz1vqMOryKHMeta?.name ?? "admin-auction",
    path: auctionz1vqMOryKHMeta?.path ?? "/admin/auction",
    meta: auctionz1vqMOryKHMeta || {},
    alias: auctionz1vqMOryKHMeta?.alias || [],
    redirect: auctionz1vqMOryKHMeta?.redirect,
    component: () => import("/app/pages/admin/auction.vue").then(m => m.default || m)
  },
  {
    name: eventsXLVRVlBuumMeta?.name ?? "admin-events",
    path: eventsXLVRVlBuumMeta?.path ?? "/admin/events",
    meta: eventsXLVRVlBuumMeta || {},
    alias: eventsXLVRVlBuumMeta?.alias || [],
    redirect: eventsXLVRVlBuumMeta?.redirect,
    component: () => import("/app/pages/admin/events.vue").then(m => m.default || m)
  },
  {
    name: indexfuvksFNEGtMeta?.name ?? "auctions",
    path: indexfuvksFNEGtMeta?.path ?? "/auctions",
    meta: indexfuvksFNEGtMeta || {},
    alias: indexfuvksFNEGtMeta?.alias || [],
    redirect: indexfuvksFNEGtMeta?.redirect,
    component: () => import("/app/pages/auctions/index.vue").then(m => m.default || m)
  },
  {
    name: indexWHm9WMDUkCMeta?.name ?? "badger",
    path: indexWHm9WMDUkCMeta?.path ?? "/badger",
    meta: indexWHm9WMDUkCMeta || {},
    alias: indexWHm9WMDUkCMeta?.alias || [],
    redirect: indexWHm9WMDUkCMeta?.redirect,
    component: () => import("/app/pages/badger/index.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policy2aYJU9s23JMeta?.name ?? "badger-privacy-policy",
    path: privacy_45policy2aYJU9s23JMeta?.path ?? "/badger/privacy-policy",
    meta: privacy_45policy2aYJU9s23JMeta || {},
    alias: privacy_45policy2aYJU9s23JMeta?.alias || [],
    redirect: privacy_45policy2aYJU9s23JMeta?.redirect,
    component: () => import("/app/pages/badger/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: support9V9Vpbod5nMeta?.name ?? "badger-support",
    path: support9V9Vpbod5nMeta?.path ?? "/badger/support",
    meta: support9V9Vpbod5nMeta || {},
    alias: support9V9Vpbod5nMeta?.alias || [],
    redirect: support9V9Vpbod5nMeta?.redirect,
    component: () => import("/app/pages/badger/support.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicehHxjwpBQQdMeta?.name ?? "badger-terms-of-service",
    path: terms_45of_45servicehHxjwpBQQdMeta?.path ?? "/badger/terms-of-service",
    meta: terms_45of_45servicehHxjwpBQQdMeta || {},
    alias: terms_45of_45servicehHxjwpBQQdMeta?.alias || [],
    redirect: terms_45of_45servicehHxjwpBQQdMeta?.redirect,
    component: () => import("/app/pages/badger/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93jK3Pyj8dSOMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93jK3Pyj8dSOMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93jK3Pyj8dSOMeta || {},
    alias: _91_46_46_46slug_93jK3Pyj8dSOMeta?.alias || [],
    redirect: _91_46_46_46slug_93jK3Pyj8dSOMeta?.redirect,
    component: () => import("/app/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91id_93skyE6bVvaPMeta?.name ?? "collections-id",
    path: _91id_93skyE6bVvaPMeta?.path ?? "/collections/:id()",
    meta: _91id_93skyE6bVvaPMeta || {},
    alias: _91id_93skyE6bVvaPMeta?.alias || [],
    redirect: _91id_93skyE6bVvaPMeta?.redirect,
    component: () => import("/app/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: indexv7p7e2w6rrMeta?.name ?? "collections",
    path: indexv7p7e2w6rrMeta?.path ?? "/collections",
    meta: indexv7p7e2w6rrMeta || {},
    alias: indexv7p7e2w6rrMeta?.alias || [],
    redirect: indexv7p7e2w6rrMeta?.redirect,
    component: () => import("/app/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: callbackVIqFc1oLf6Meta?.name ?? "discord-callback",
    path: callbackVIqFc1oLf6Meta?.path ?? "/discord/callback",
    meta: callbackVIqFc1oLf6Meta || {},
    alias: callbackVIqFc1oLf6Meta?.alias || [],
    redirect: callbackVIqFc1oLf6Meta?.redirect,
    component: () => import("/app/pages/discord/callback.vue").then(m => m.default || m)
  },
  {
    name: index8T4PBHOxLqMeta?.name ?? "discord",
    path: index8T4PBHOxLqMeta?.path ?? "/discord",
    meta: index8T4PBHOxLqMeta || {},
    alias: index8T4PBHOxLqMeta?.alias || [],
    redirect: index8T4PBHOxLqMeta?.redirect,
    component: () => import("/app/pages/discord/index.vue").then(m => m.default || m)
  },
  {
    name: eventsk5yx3AVVmZMeta?.name ?? "events",
    path: eventsk5yx3AVVmZMeta?.path ?? "/events",
    meta: eventsk5yx3AVVmZMeta || {},
    alias: eventsk5yx3AVVmZMeta?.alias || [],
    redirect: eventsk5yx3AVVmZMeta?.redirect,
    component: () => import("/app/pages/events.vue").then(m => m.default || m)
  },
  {
    name: gobytOfADRZqdlMeta?.name ?? "goby",
    path: gobytOfADRZqdlMeta?.path ?? "/goby",
    meta: gobytOfADRZqdlMeta || {},
    alias: gobytOfADRZqdlMeta?.alias || [],
    redirect: gobytOfADRZqdlMeta?.redirect,
    component: () => import("/app/pages/goby.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: legal_45noticeNNxwVI9S92Meta?.name ?? "legal-notice",
    path: legal_45noticeNNxwVI9S92Meta?.path ?? "/legal-notice",
    meta: legal_45noticeNNxwVI9S92Meta || {},
    alias: legal_45noticeNNxwVI9S92Meta?.alias || [],
    redirect: legal_45noticeNNxwVI9S92Meta?.redirect,
    component: () => import("/app/pages/legal-notice.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: early_45accessctisd2vT87Meta?.name ?? "mint-early-access",
    path: early_45accessctisd2vT87Meta?.path ?? "/mint/early-access",
    meta: early_45accessctisd2vT87Meta || {},
    alias: early_45accessctisd2vT87Meta?.alias || [],
    redirect: early_45accessctisd2vT87Meta?.redirect,
    component: () => import("/app/pages/mint/early-access.vue").then(m => m.default || m)
  },
  {
    name: indexUxcQIpR6GxMeta?.name ?? "mint",
    path: indexUxcQIpR6GxMeta?.path ?? "/mint",
    meta: indexUxcQIpR6GxMeta || {},
    alias: indexUxcQIpR6GxMeta?.alias || [],
    redirect: indexUxcQIpR6GxMeta?.redirect,
    component: () => import("/app/pages/mint/index.vue").then(m => m.default || m)
  },
  {
    name: studio4i2Yxg5GiNMeta?.name ?? "mint-studio",
    path: studio4i2Yxg5GiNMeta?.path ?? "/mint/studio",
    meta: studio4i2Yxg5GiNMeta || {},
    alias: studio4i2Yxg5GiNMeta?.alias || [],
    redirect: studio4i2Yxg5GiNMeta?.redirect,
    component: () => import("/app/pages/mint/studio.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicenXeZoWlia8Meta?.name ?? "mint-terms-of-service",
    path: terms_45of_45servicenXeZoWlia8Meta?.path ?? "/mint/terms-of-service",
    meta: terms_45of_45servicenXeZoWlia8Meta || {},
    alias: terms_45of_45servicenXeZoWlia8Meta?.alias || [],
    redirect: terms_45of_45servicenXeZoWlia8Meta?.redirect,
    component: () => import("/app/pages/mint/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: _91id_93nYorKaOVoNMeta?.name ?? "mints-id",
    path: _91id_93nYorKaOVoNMeta?.path ?? "/mints/:id()",
    meta: _91id_93nYorKaOVoNMeta || {},
    alias: _91id_93nYorKaOVoNMeta?.alias || [],
    redirect: _91id_93nYorKaOVoNMeta?.redirect,
    component: () => import("/app/pages/mints/[id].vue").then(m => m.default || m)
  },
  {
    name: indexLBanV1CnnxMeta?.name ?? "mints",
    path: indexLBanV1CnnxMeta?.path ?? "/mints",
    meta: indexLBanV1CnnxMeta || {},
    alias: indexLBanV1CnnxMeta?.alias || [],
    redirect: indexLBanV1CnnxMeta?.redirect,
    component: () => import("/app/pages/mints/index.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new",
    path: new8XRwwjkl9dMeta?.path ?? "/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: indexRBbaGpDb0BMeta?.name ?? "nfts-id",
    path: indexRBbaGpDb0BMeta?.path ?? "/nfts/:id()",
    meta: indexRBbaGpDb0BMeta || {},
    alias: indexRBbaGpDb0BMeta?.alias || [],
    redirect: indexRBbaGpDb0BMeta?.redirect,
    component: () => import("/app/pages/nfts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createNVwQmIo8kUMeta?.name ?? "nfts-create",
    path: createNVwQmIo8kUMeta?.path ?? "/nfts/create",
    meta: createNVwQmIo8kUMeta || {},
    alias: createNVwQmIo8kUMeta?.alias || [],
    redirect: createNVwQmIo8kUMeta?.redirect,
    component: () => import("/app/pages/nfts/create.vue").then(m => m.default || m)
  },
  {
    name: indexob1pC6VD6NMeta?.name ?? "nfts",
    path: indexob1pC6VD6NMeta?.path ?? "/nfts",
    meta: indexob1pC6VD6NMeta || {},
    alias: indexob1pC6VD6NMeta?.alias || [],
    redirect: indexob1pC6VD6NMeta?.redirect,
    component: () => import("/app/pages/nfts/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wccPPBXqnRMeta?.name ?? "offers-id",
    path: _91id_93wccPPBXqnRMeta?.path ?? "/offers/:id()",
    meta: _91id_93wccPPBXqnRMeta || {},
    alias: _91id_93wccPPBXqnRMeta?.alias || [],
    redirect: _91id_93wccPPBXqnRMeta?.redirect,
    component: () => import("/app/pages/offers/[id].vue").then(m => m.default || m)
  },
  {
    name: uploadBbawmCa51zMeta?.name ?? "offers-upload",
    path: uploadBbawmCa51zMeta?.path ?? "/offers/upload",
    meta: uploadBbawmCa51zMeta || {},
    alias: uploadBbawmCa51zMeta?.alias || [],
    redirect: uploadBbawmCa51zMeta?.redirect,
    component: () => import("/app/pages/offers/upload.vue").then(m => m.default || m)
  },
  {
    name: passkey_45test1U4iIr5CfwMeta?.name ?? "passkey-test",
    path: passkey_45test1U4iIr5CfwMeta?.path ?? "/passkey-test",
    meta: passkey_45test1U4iIr5CfwMeta || {},
    alias: passkey_45test1U4iIr5CfwMeta?.alias || [],
    redirect: passkey_45test1U4iIr5CfwMeta?.redirect,
    component: () => import("/app/pages/passkey-test.vue").then(m => m.default || m)
  },
  {
    name: premiumEP1PXaA2PJMeta?.name ?? "premium",
    path: premiumEP1PXaA2PJMeta?.path ?? "/premium",
    meta: premiumEP1PXaA2PJMeta || {},
    alias: premiumEP1PXaA2PJMeta?.alias || [],
    redirect: premiumEP1PXaA2PJMeta?.redirect,
    component: () => import("/app/pages/premium.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pYch2M9GePMeta?.name ?? "profile-embed-id",
    path: _91id_93pYch2M9GePMeta?.path ?? "/profile-embed/:id()",
    meta: _91id_93pYch2M9GePMeta || {},
    alias: _91id_93pYch2M9GePMeta?.alias || [],
    redirect: _91id_93pYch2M9GePMeta?.redirect,
    component: () => import("/app/pages/profile-embed/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Kujv0wYEYHMeta?.name ?? "profile-id",
    path: _91id_93Kujv0wYEYHMeta?.path ?? "/profile/:id()",
    meta: _91id_93Kujv0wYEYHMeta || {},
    alias: _91id_93Kujv0wYEYHMeta?.alias || [],
    redirect: _91id_93Kujv0wYEYHMeta?.redirect,
    component: () => import("/app/pages/profile/[id].vue").then(m => m.default || m)
  },
  {
    name: profilesq0TH7YXrSuMeta?.name ?? "profiles",
    path: profilesq0TH7YXrSuMeta?.path ?? "/profiles",
    meta: profilesq0TH7YXrSuMeta || {},
    alias: profilesq0TH7YXrSuMeta?.alias || [],
    redirect: profilesq0TH7YXrSuMeta?.redirect,
    component: () => import("/app/pages/profiles.vue").then(m => m.default || m)
  },
  {
    name: registermu8R7rSP5uMeta?.name ?? "register",
    path: registermu8R7rSP5uMeta?.path ?? "/register",
    meta: registermu8R7rSP5uMeta || {},
    alias: registermu8R7rSP5uMeta?.alias || [],
    redirect: registermu8R7rSP5uMeta?.redirect,
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: didCfLdYe1mNuMeta?.name ?? "user-claim-did",
    path: didCfLdYe1mNuMeta?.path ?? "/user/claim/did",
    meta: didCfLdYe1mNuMeta || {},
    alias: didCfLdYe1mNuMeta?.alias || [],
    redirect: didCfLdYe1mNuMeta?.redirect,
    component: () => import("/app/pages/user/claim/did.vue").then(m => m.default || m)
  },
  {
    name: callback2gi6kDy7w0Meta?.name ?? "user-claim-discord-callback",
    path: callback2gi6kDy7w0Meta?.path ?? "/user/claim/discord/callback",
    meta: callback2gi6kDy7w0Meta || {},
    alias: callback2gi6kDy7w0Meta?.alias || [],
    redirect: callback2gi6kDy7w0Meta?.redirect,
    component: () => import("/app/pages/user/claim/discord/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackUXz1QU7VLHMeta?.name ?? "user-claim-twitter-callback",
    path: callbackUXz1QU7VLHMeta?.path ?? "/user/claim/twitter/callback",
    meta: callbackUXz1QU7VLHMeta || {},
    alias: callbackUXz1QU7VLHMeta?.alias || [],
    redirect: callbackUXz1QU7VLHMeta?.redirect,
    component: () => import("/app/pages/user/claim/twitter/callback.vue").then(m => m.default || m)
  },
  {
    name: premiummGK9BexxYhMeta?.name ?? "user-premium",
    path: premiummGK9BexxYhMeta?.path ?? "/user/premium",
    meta: premiummGK9BexxYhMeta || {},
    alias: premiummGK9BexxYhMeta?.alias || [],
    redirect: premiummGK9BexxYhMeta?.redirect,
    component: () => import("/app/pages/user/premium.vue").then(m => m.default || m)
  },
  {
    name: profilesxSppIEAXTuMeta?.name ?? "user-profiles",
    path: profilesxSppIEAXTuMeta?.path ?? "/user/profiles",
    meta: profilesxSppIEAXTuMeta || {},
    alias: profilesxSppIEAXTuMeta?.alias || [],
    redirect: profilesxSppIEAXTuMeta?.redirect,
    component: () => import("/app/pages/user/profiles.vue").then(m => m.default || m)
  },
  {
    name: callbackgdPkb8euiAMeta?.name ?? "wallet-claim-discord-callback",
    path: callbackgdPkb8euiAMeta?.path ?? "/wallet/claim/discord/callback",
    meta: callbackgdPkb8euiAMeta || {},
    alias: callbackgdPkb8euiAMeta?.alias || [],
    redirect: callbackgdPkb8euiAMeta?.redirect,
    component: () => import("/app/pages/wallet/claim/discord/callback.vue").then(m => m.default || m)
  },
  {
    name: callbackNT3xMmcnmOMeta?.name ?? "wallet-claim-twitter-callback",
    path: callbackNT3xMmcnmOMeta?.path ?? "/wallet/claim/twitter/callback",
    meta: callbackNT3xMmcnmOMeta || {},
    alias: callbackNT3xMmcnmOMeta?.alias || [],
    redirect: callbackNT3xMmcnmOMeta?.redirect,
    component: () => import("/app/pages/wallet/claim/twitter/callback.vue").then(m => m.default || m)
  },
  {
    name: indexzk34bxS2TyMeta?.name ?? "wallet",
    path: indexzk34bxS2TyMeta?.path ?? "/wallet",
    meta: indexzk34bxS2TyMeta || {},
    alias: indexzk34bxS2TyMeta?.alias || [],
    redirect: indexzk34bxS2TyMeta?.redirect,
    component: () => import("/app/pages/wallet/index.vue").then(m => m.default || m)
  }
]