import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _01_amplify_apis_client_2jPLb1KLd6 from "/app/plugins/01.amplify-apis.client.ts";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/app/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import pwa_icons_Y6agwlnXZq from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_Eorgdr12jA from "/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_K1KWfQJd0t from "/app/node_modules/nuxt-progress/dist/runtime/plugin.mjs";
import script_YrMPz5ICtR from "/app/node_modules/@nuxtjs/turnstile/dist/runtime/plugins/script.mjs";
import plugin_client_KZ0f4ARq4r from "/app/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.mjs";
import plugin_client_i8AMfKeYnY from "/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_QMFgzss1s4 from "/app/plugins/axios.ts";
import i18n_VfGcjrvSkj from "/app/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _01_amplify_apis_client_2jPLb1KLd6,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  pwa_icons_Y6agwlnXZq,
  pwa_client_Eorgdr12jA,
  plugin_K1KWfQJd0t,
  script_YrMPz5ICtR,
  plugin_client_KZ0f4ARq4r,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  axios_QMFgzss1s4,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk
]