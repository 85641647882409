{
  "header": {
    "mint": "铸造",
    "mints": "新铸造",
    "rankings": "热门",
    "collections": "收藏品",
    "profiles": "Top Profiles",
    "activity": "活动",
    "closeMainMenu": "关闭主菜单",
    "openMainMenu": "打开主菜单",
    "search": "搜索...",
    "noResults": "未找到结果",
    "nothingFound": "未找到与此搜索词相关的内容。请再试一次。",
    "login": "登录",
    "myProfiles": "我的个人资料",
    "logout": "登出"
  },
  "mintGarden": "MintGarden",
  "home": {
    "heroTitle": "拥抱去中心化和无需信任的NFT",
    "heroSubtitle": "在Chia区块链创作、收藏、交易NFT和数字艺术作品",
    "exploreAndMint": "在Chia生态系统中探索并铸造NFT。",
    "exploreCollections": "浏览收藏品集",
    "startMinting": "开始铸造",
    "weeklyTopCollections": "每周热门收藏",
    "weeklyTopCreators": "每周热门创造者",
    "exploreAllCollections": "探索所有收藏",
    "exploreAllCreators": "探索所有创造者",
    "activeAuctions": "正在拍卖中",
    "notableCollections": "知名收藏品",
    "supporters": "Supporters",
    "allInOneNFT": "Chia上的一站式NFT平台",
    "embraceNFTs": "拥抱去中心化和无需信任的NFT",
    "experiencePower": "通过MintGarden提供的全套工具，体验Chia NFT的强大力量，从MintGarden Studio的铸造，到使用Discord机器人参与社区，再到通过MintGarden网站探索Chia NFT生态全景。",
    "createAndMint": "创建并铸造Chia NFT。",
    "mintDescription": "无缝铸造单个或批量的Chia NFT直接到您的钱包，为原创出处分配DID，并为Dexie交易所直接创建报价。",
    "engageCommunity": "启动您的NFT社区。",
    "communityDescription": "及时更新二级市场交易，为经过验证的NFT所有者保留独家Discord频道。",
    "featureExploreCollections": "探索Chia NFT收藏品。",
    "collectionsDescription": "发现收藏品，连接创作者和收藏者，并在链上活动和去中心化交易提供上保持更新。"
  },
  "collections": {
    "collection": "收藏品",
    "volume": "交易额",
    "floorPrice": "最低价格",
    "numTrades": "#交易量",
    "numItems": "#总供应量",
    "topCollections": "热门收藏",
    "days7": "7天",
    "days30": "30天",
    "allTime": "全部时间"
  },
  "collection": {
    "by": "由",
    "mint": "铸造",
    "items": "项目",
    "activity": "活动",
    "trades": "交易",
    "floor": "地板价",
    "totalVolume": "总交易额",
    "recent": "最近",
    "lowestPrice": "最低价格",
    "highestPrice": "最高价格",
    "Offer: Low to High": "报价：从低到高",
    "Offer: New to Old": "报价：从新到旧",
    "Rank: Rarest to Common": "等级：从稀有到普通",
    "Recently Moved": "最近移动",
    "Oldest to Newest": "最旧到最新",
    "price": "价格",
    "highest_bid": "最高拍价",
    "reserve": "底价",
    "no_bid": "流拍",
    "starts_in": "开始时间",
    "ends_in": "结束时间",
    "shareWithHandle": "在{'@'}MintGarden_io上查看{'@'}{handle}的个人收藏品！ \uD83C\uDF31\n\n{url}",
    "shareWithoutHandle": "在{'@'}MintGarden_io上查看这个个人收藏品！ \uD83C\uDF31\n\n{url}"
  },
  "mint": {
    "mintgarden_studio": "MintGarden Studio",
    "email_address": "电子邮件地址",
    "enter_your_email": "输入您的电子邮件",
    "submitted": "已提交！",
    "request_access": "申请访问",
    "minting_made_simple": "铸造从未如此轻松",
    "description": "MintGarden Studio是一款桌面应用程序，可以让艺术家轻松地将他们的作品铸造为Chia区块链上的NFT。加入我们的排队列表，抢先获得早期访问权限。",
    "share_creations": "与世界分享您的作品。",
    "stay_in_control": "一切尽在掌握中",
    "stay_in_control_description": "MintGarden Studio直接在您的本地机器上创建NFT，不涉及第三方。因此，您的私钥始终在您手中。",
    "so_simple": "如此简单，您会爱上它",
    "so_simple_description": "我们会处理所有棘手的部分，如哈希，元数据格式化和上传您的图像到IPFS。",
    "bulk_minting": "批量铸造",
    "bulk_minting_description": "通过每组铸造25个NFT，MintGarden Studio可以让您更快地向社区提供大量的收藏品。",
    "random_minting": "随机铸造",
    "random_minting_description": "在收到付款后按需铸造NFT，以确保您的收藏品的公平和随机分布。",
    "get_early_access": "提前获取 MintGarden Studio 的使用权。",
    "early_access_description": "我们将邀请一小部分用户在 MintGarden Studio 对公众开放前试用。请输入您的电子邮件以获取访问权。"
  },
  "activity": {
    "ago": "前",
    "mint": "铸造",
    "minted": "已铸造",
    "trade": "交易",
    "traded": "已交易",
    "transfer": "转移",
    "transferred": "已转移",
    "burn": "销毁",
    "burned": "已销毁",
    "filter": "过滤器",
    "filters": "筛选器",
    "close_menu": "关闭菜单",
    "event_type": "事件类型",
    "collections": "收藏",
    "asset": "资产",
    "price": "价格",
    "sender": "Sender",
    "recipient": "接收者",
    "time": "时间"
  },
  "details": {
    "attributes": "属性",
    "provenance": "来源",
    "title": "详情",
    "mintHeight": "铸造高度",
    "edition": "版本",
    "series": "系列",
    "perpetualRoyalties": "永久版税",
    "openRarityRank": "OpenRarity 排名",
    "license": "许可证",
    "licenseMismatch": "许可证（哈希不匹配）",
    "noLicense": "无许可证",
    "inspectOnSpacescan": "在spacescan.io上检查",
    "openOriginalDataURI": "打开原始数据URI",
    "openOriginalMetadataURI": "打开原始元数据URI",
    "creator": "创造者",
    "owner": "所有者",
    "burned": "已销毁",
    "collection": "收藏",
    "listings": "商品列表",
    "bids": "出价",
    "noOffers": "没有报价",
    "uploadFirstOffer": "立即上传您的首个报价。",
    "uploadOffer": "上传报价",
    "price": "价格",
    "activeSince": "自从活跃",
    "actions": "操作",
    "auction": "拍卖",
    "auction_ended": "拍卖已结束",
    "highest_bid": "最高拍价",
    "no_bid": "流拍",
    "no_bid_reserve": "流拍, 最少. {price} XCH",
    "auction_ends_in": "拍卖结束时间",
    "overtime_ends_in": "加时结束时间",
    "auction_disclaimer": " 本次拍卖由当前NFT所有者进行，而不是由 MintGarden 进行。\n他们将在拍卖结束后手动选择中标者。"
  },
  "offers": {
    "copyOffer": "复制报价",
    "downloadOffer": "下载报价",
    "takeOffer": "接受报价",
    "connectWallet": "连接钱包",
    "offering": "提供",
    "requesting": "请求",
    "offer": "提供",
    "plus": "加上",
    "including": "包括",
    "creator_royalties": "创作者版税"
  },
  "provenance": {
    "mintedByPre": "由 ",
    "mintedByPost": "铸造",
    "transferredToPre": "转移到 ",
    "transferredToPost": "",
    "acquiredByPre": "由 ",
    "acquiredByPost": " 获得",
    "burnedPre": "已销毁",
    "burnedPost": "",
    "auctionStartedPre": "拍卖开始",
    "auctionStartedPost": "",
    "auctionEndedPre": "拍卖结束",
    "auctionEndedPost": "",
    "andOtherAddresses": "和其他{count}个地址"
  },
  "profile": {
    "topCreators": "热门创造者",
    "share": "分享",
    "report": "报告",
    "created": "已创建",
    "collections": "收藏",
    "owned": "拥有",
    "activity": "活动",
    "shareWithHandle": "在{'@'}MintGarden_io上查看{'@'}{handle}的个人资料！ \uD83C\uDF31\n\n{url}",
    "shareWithoutHandle": "在{'@'}MintGarden_io上查看这个个人资料！ \uD83C\uDF31\n\n{url}"
  },
  "address": {
    "address": "Address",
    "owned": "拥有",
    "activity": "活动"
  },
  "footer": {
    "description": "在Chia生态系统中探索和铸造NFT。",
    "support": "支持",
    "documentation": "文档",
    "legalnotice": "Legal Notice",
    "status": "状态",
    "developers": "Developers",
    "api": "API",
    "blog": "Blog",
    "language": "语言",
    "allRightsReserved": "保留所有权利。"
  },
  "register": {
    "registerTitle": "注册新账户",
    "or": "或者",
    "signIn": "登录到已有账户",
    "email": "电子邮件地址",
    "password": "密码",
    "passwordHint": "至少8个字符；字母，数字和符号",
    "verificationCode": "验证码",
    "welcome": "欢迎，{email}！",
    "redirecting": "正在跳转...",
    "redirectHelp": "如果没有跳转，请点击...",
    "confirm": "确认",
    "register": "注册",
    "profileCustomization": "个人资料定制",
    "profileCustomizationDescription": "定制您在MintGarden上的个人资料（DIDs）的外观，包括个人资料名称和您的头像。",
    "socialVerification": "社交验证",
    "socialVerificationDescription": "将您的个人资料（DIDs）连接到您的社交媒体账户，以增加您的个人资料的信息。",
    "notificationsOnBids": "出价通知",
    "notificationsOnBidsDescription": "当您在任何热门交易所收到您的NFT的出价时，得到通知。"
  },
  "login": {
    "resetAccount": "重置您的账户",
    "signInAccount": "登录您的账户",
    "or": "或者",
    "tryLogin": "尝试登录",
    "registerNewAccount": "注册新账户",
    "emailAddress": "电子邮件地址",
    "password": "密码",
    "verificationCode": "验证码",
    "forgotPassword": "忘记密码?",
    "resetPassword": "重置密码",
    "signIn": "登录"
  },
  "user": {
    "myProfiles": "我的资料",
    "loadingProfiles": "正在加载资料...",
    "claimAProfile": "创建一个个人资料",
    "claimAnotherProfile": "再创建一个个人资料",
    "handle": "MintGarden Handle",
    "name": "姓名",
    "impersonationWarning": "冒充另一个用户将导致永久禁止。",
    "biography": "简介",
    "connectTwitter": "连接X账户",
    "twitterHandle": "X账户名称",
    "unlink": "取消链接",
    "connectDiscord": "连接Discord账户",
    "discordProfile": "Discord账户名称",
    "notificationPreferences": "通知偏好",
    "beta": "测试版",
    "disableAll": "禁用所有",
    "enableAll": "启用所有",
    "onNewBid": "您收到了一个新的出价",
    "onOwnNftForSale": "您有一个NFT正在销售",
    "onOfferAccepted": "您有一个NFT已被售出",
    "onBidCancelled": "您的一个NFT的出价已被取消",
    "onOwnForSaleCancelled": "您取消了一个销售报价",
    "save": "保存",
    "profileUpdateSuccess": "个人资料成功更新！",
    "claimProfile": "创建个人资料",
    "profileClaimSuccess": "成功创建个人资料！",
    "back": "返回",
    "verificationMethod": "您想要如何验证？",
    "walletConnect": "钱包连接",
    "walletConnectDescription": "使用WalletConnect签署消息",
    "cliSignMessage": "Chia Command Line",
    "cliSignMessageDescription": "使用CLI签署消息",
    "pawket": "Pawket",
    "pawketDescription": "使用Pawket钱包签署消息",
    "signMessage": "使用WalletConnect签署消息",
    "connectChiaWallet": "使用WalletConnect连接到您的Chia钱包，并签署消息以证明所有权。",
    "waitingConfirmation": "等待钱包确认...",
    "fetchProfiles": "获取个人资料",
    "signMessageButton": "签名信息",
    "connectedTo": "已连接到",
    "wallet": "钱包。",
    "whichProfileClaim": "您要创建哪个个人资料？",
    "syncWalletNote": "如果您在这里缺少一个个人资料，请确保钱包已同步。",
    "fetchProfilesFail": "无法获取您的个人资料。请手动输入您的DID。",
    "disconnect": "断开连接",
    "connectWallet": "连接钱包"
  }
}
