declare global {
  const API_ROOT: string;
}

export const getApiRoot = (): string => {
  if (typeof API_ROOT !== 'undefined') {
    return API_ROOT;
  }
  const config = useRuntimeConfig();
  return config.public.apiRoot;
};
